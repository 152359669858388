import React, {createContext, useContext, useEffect} from 'react';

const CooklerContext = createContext(null);

export function CooklerProvider({children}) {
    useEffect(() => {
        let tracked = false;

        const cooklerHandler = (e) => {
            if (!tracked) {
                tracked = true;
                window.gtag('event', 'cookler_event', {
                    event_category: 'Interaction',
                    event_label: 'WH Parameter',
                    value: e.detail.wh
                });
            }
        };

        document.addEventListener('cookler', cooklerHandler);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('cookler', cooklerHandler);
        };
    }, []); // Empty dependency array ensures this only runs once

    return (
        <CooklerContext.Provider value={'bh'}>
            {children}
        </CooklerContext.Provider>
    );
}

export const useCookler = () => useContext(CooklerContext);