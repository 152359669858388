import {
    Box,
    Container,
    Heading,
    Text
} from "@chakra-ui/react";
import {Steps} from "../steps/Steps";
import {SuccessStory} from "../success-story/SuccessStory";
import {useState} from "react";

function Step(props) {
    const [showSuccessStory, setShowSuccessStory] = useState(!!props.successStory);

    const handleHideSuccessStory = () => setShowSuccessStory(false);

    return (
        <>
            <Box>
                <Steps currentStep={props.currentStep} maxSteps="8" backLink={props.backLink}/>
            </Box>
            {showSuccessStory && props.successStory ? (
                <Container  >
                    <SuccessStory id={props.successStory} onHide={handleHideSuccessStory}/>
                </Container>
            ) : (
                <Container p={[2, 5]}>
                    <Box
                        borderRadius="lg"
                        textAlign={"center"}
                    >
                        {props.heading &&
                            <Heading as={"h2"} size={"lg"} mb={5}>
                                {props.heading}
                            </Heading>}

                        {props.description && <Text>{props.description}</Text>}

                        {props.children}
                    </Box>
                </Container>
            )}
        </>
    );
}

export default Step;
