import graphImage from '../../assets/step6/graph.svg';
import Step from "../../components/step/Step";
import {useIntl} from "react-intl";
import {Box, Heading, Image, SimpleGrid} from "@chakra-ui/react";
import NextButton from "../../components/next-button/NextButton";
import ConditionalContent from "../../components/condtional-content/ConditionalContent";

function Step6() {
    const intl = useIntl();

    return (
        <Step currentStep={6}
              backLink={'/step5'}>
            <Box textAlign={'left'}>
                <Heading fontSize={'xl'}>
                    <ConditionalContent condition="wh">
                        {intl.formatMessage({id: 'step6.headline'})}
                    </ConditionalContent>
                    <ConditionalContent condition="bh">
                        {intl.formatMessage({id: 'step6.headline-bh'})}
                    </ConditionalContent>
                </Heading>

                <Heading mt={5} fontSize={'lg'}>
                    {intl.formatMessage({id: 'step6.subHeadline'})}
                </Heading>

                <Box mt={4} position={'relative'}>
                    <Box position={'absolute'}
                         right={2}
                         top={'48%'}
                         fontSize={['lg','xx-large']}
                         fontWeight={'bold'}
                         color={'red'}>
                        {intl.formatMessage({id: 'step6.graph.diet'})}
                    </Box>
                    <Image width={'100%'} src={graphImage}/>

                    <ConditionalContent condition="bh">
                        <SimpleGrid columns={2} spacing={10} fontSize={'sm'} mt={2}>
                            <Box textAlign={'left'}>
                                {intl.formatMessage({id: 'step6.graph.left'})}
                            </Box>
                            <Box textAlign={'right'}>
                                {intl.formatMessage({id: 'step6.graph.right'})}
                            </Box>
                        </SimpleGrid>
                    </ConditionalContent>
                </Box>
            </Box>

            <NextButton redirect={'step7'}/>
        </Step>
    );
}

export default Step6;
