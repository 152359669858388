import React from "react";
import {Box, Button, Image, Text} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";
import successStoryImage1 from '../../assets/success-stories/success1.jpg';
import successStoryImage2 from '../../assets/success-stories/success2.jpg';
import successStoryImage3 from '../../assets/success-stories/success3.jpg';
import ConditionalContent from "../condtional-content/ConditionalContent";

export const SuccessStory = ({id, onHide}) => {
    const whDescriptionId = `successStory.${id}.description`;
    const bhDescriptionId = `successStory.${id}.description-bh`;

    const imageMap = {
        1: successStoryImage1,
        2: successStoryImage2,
        3: successStoryImage3,
    };

    const image = imageMap[id];

    return (
        <Box borderWidth="1px" rounded="lg" overflow="hidden">
            <Box>
                <Box textAlign={'center'} p={2} background={'secondary'} color={'white'} fontWeight={'bold'}
                     fontSize={'sm'}>
                    <FormattedMessage id={'successStory.title'}/>
                </Box>

                <Box alignItems="center" textAlign="center">
                    <Image display="inline" src={image}/>

                    <ConditionalContent condition="wh">
                        <Text fontSize={'xs'} color={'gray'}>
                            <FormattedMessage id={'successStory.imageCaption.wh'}/>
                        </Text>
                    </ConditionalContent>
                </Box>

                <Text p={5} pt={3} pb={3} fontSize={["md","xl"]} textAlign={'center'}>
                    <ConditionalContent condition="wh">
                        <FormattedMessage id={whDescriptionId}
                                          values={{
                                              b: (chunks) => <strong>{chunks}</strong>
                                          }}/>
                    </ConditionalContent>
                    <ConditionalContent condition="bh">
                        <FormattedMessage id={bhDescriptionId}
                                          values={{
                                              b: (chunks) => <strong>{chunks}</strong>
                                          }}/>
                    </ConditionalContent>
                </Text>

                <Box p={3} pt={0}>
                    <Button bg="secondary"
                            color={"white"}
                            _hover={{
                                bg: "#f8d10d",
                                color: "black",
                            }}
                            size="md"
                            p={5}
                            w={'100%'} onClick={onHide}>
                        <FormattedMessage id={'form.button.next'}/>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
