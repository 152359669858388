import './Loader.scss';
import {Progress, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import Step from "../../components/step/Step";

function Loader() {
    const intl = useIntl();

    let [percentage, setPercentage] = useState(0);

    let navigate = useNavigate();

    useEffect(() => {
        if (percentage === 100) {
           navigate('../step8', {replace: true});
        }

        if (percentage < 100) {
            const interval = setInterval(() => {
                setPercentage(percentage => percentage + 10);
            }, 150);
            return () => clearInterval(interval);
        }
    }, [intl, percentage, navigate]);

    return (
        <Step currentStep={7}
              backLink={'/step6'}>

            <Text mb={4}>
                {intl.formatMessage({id: 'loader.text'})}
            </Text>

            <Progress colorScheme='orange' height='32px' value={percentage} />
        </Step>
    )
}

export default Loader;
